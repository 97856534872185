<!--派单规则设置-->
<template>
  <div>
    <div style="display: flex;justify-content: space-between;align-items: center;">
      <div>
        <span style="font-size: 24px;font-weight: 500;color: #707070;">派单规则设置</span>
      </div>
      <div>
        <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
      </div>
    </div>

    <el-card shadow="never" style="margin-top: 20px;">
      <!--<div>-->
      <!--  <el-button class="custom-button" style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;">-->
      <!--    <i class="el-icon-devops" />-->
      <!--    <span style="vertical-align: middle">下载批量模板</span>-->
      <!--  </el-button>-->
      <!--  <el-button class="custom-button" style="background: #3F4157!important;border-color: #3F4157!important;color: #FFFFFF!important;">-->
      <!--    <i class="el-icon-devops" />-->
      <!--    <span style="vertical-align: middle">批量导入服务商</span>-->
      <!--  </el-button>-->
      <!--</div>-->

      <div class="divstyle1">
        <div class="stylekaiguan">
          <div>
            <span class="divstyle2">区域：</span>
          </div>
          <div class="divstyle3">
            <div class="radio">
              <el-cascader
                v-model="value"
                :options="city"
                :props="{ checkStrictly: true }"
                @change="handleChange"
                clearable>
              </el-cascader>
              <span class="divstyle4">100个服务商</span>
            </div>
          </div>
        </div>
        <div class="divstyle6">
          <span class="fontbianbju" v-if="value == true">已启用</span>
          <span class="fontbianbju" v-else>已禁用</span>
          <el-switch
            v-model="value"
            inactive-color="#ff4949">
          </el-switch>
        </div>
      </div>

      <div class="div-top">
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div style="display: flex;align-items: center;">
            <span class="jiuyuanleixing">全选：</span>
            <span class="divstyle7">
              <el-checkbox v-model="checked">救援类型</el-checkbox>
              <el-checkbox v-model="checked1">自动调度</el-checkbox>
              <el-checkbox v-model="piliang">批量添加服务商</el-checkbox>
            </span>
            <!--<el-autocomplete-->
            <!--  v-if="piliang == true"-->
            <!--  class="inline-input"-->
            <!--  v-model="state2"-->
            <!--  clearable-->
            <!--  :fetch-suggestions="querySearch1"-->
            <!--  placeholder="请输入内容"-->
            <!--  :trigger-on-focus="false"-->
            <!--  @select="handleSelect"-->
            <!--&gt;</el-autocomplete>-->
            <el-cascader
              v-if="piliang == true"
              class="inline-input"
              v-model="value1"
              :options="city"
              :props="{ checkStrictly: true }"
              @change="handleChange"
              clearable>
            </el-cascader>
            <el-select v-model="state2" filterable placeholder="请选择" v-if="piliang == true" clearable class="inline-input">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>

      <!--每一块-->
      <div class="meiyikuai">
        <div v-for="(item,index) in List" :key="index" class="bottomstyle">
          <div class="meiyikuaidiv">
            <div>
              <el-checkbox v-model="item.switch">{{item.name}}</el-checkbox>
              <el-checkbox v-if="item.switch === true" v-model="item.switch1">{{item.title}}</el-checkbox>
            </div>
            <div class="meiyikuaidivright" v-if="item.switch === true">
              <el-autocomplete
                class="inline-input"
                v-model="item.state2"
                clearable
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                @select="handleSelect1"
              ></el-autocomplete>
              <el-button type="primary" size="small" class="zuoleft" @click="add(item,index)">添加</el-button>
            </div>
          </div>
          <!--底部-->
          <div class="meiyikuaidivbottom" v-if="item.switch === true">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="data"
              :headerCellStyle="headerCellStyle"
              @select="select"
            >
              <template v-slot:bili= "{ scope }">
                <el-input placeholder="请输入内容" v-model="scope.row.bili" clearable>
                  <template slot="append">%</template>
                </el-input>
              </template>

              <template v-slot:chaoshi= "{ scope }">
                <el-input placeholder="请输入内容" v-model="scope.row.chaoshi" clearable>
                  <template slot="append">分钟</template>
                </el-input>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link type="danger" @click="del(scope)">删除</el-link>
              </template>
            </my-table>
          </div>
        </div>
      </div>

    </el-card>

    <div style="margin-top: 20px;text-align: center;">
      <el-button style="width: 240px;">取消</el-button>
      <el-button
        style="margin-left: 20px;width: 240px;"
        type="primary"
        @click="save()">提交
      </el-button>
    </div>

  </div>
</template>

<script>
//引入的接口
import {
  getcityList,
} from '@/api/yunli'
export default {
  data(){
    return{
      form:{},
      checked:true,
      checked1:false,
      //城市列表
      city:[],

      value:false,

      //批量添加服务商
      piliang:false,
      value1:'',
      options: [{
        value: '选项1',
        label: '一键救援'
      }, {
        value: '选项2',
        label: '一键救援1'
      }, {
        value: '选项3',
        label: '一键救援2'
      }, {
        value: '选项4',
        label: '一键救援3'
      }, {
        value: '选项5',
        label: '一键救援4'
      }],
      state2: '' ,

      restaurants: [
        { "value": "一键救援", },
        { "value": "一键救援1", },
        { "value": "一键救援2", },
        { "value": "一键救援3", },
      ],

      List:[
        {
          switch:false,
          name:'拖车',
          switch1:false,
          title:'自动调度',
          state2:'',
        },
        {
          switch:false,
          name:'搭电',
          switch1:false,
          title:'自动调度',
          state2:''
        },
      ],

      //单个项目的点击
      item:null,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "服务商名称",
          prop: "label",
          isShow: true
        },
        {
          label: "派单比例",
          prop: "bili",
          slot: 'bili',
          isShow: true
        },
        {
          label: "超时自动撤回(0不自动撤回)",
          prop: "chaoshi",
          slot: 'chaoshi',
          isShow: true
        },
        {
          label: "操作",
          slot: "operationSlot",
          isShow: true
        },
      ],
      data:[
        {
          label:'绵阳城区天地行汽车修理救援服务部',
          bili:'100',
          chaoshi:'0'
        }
      ]

    }
  },

  mounted() {
    //调用获取城市列表
    this.getCity();
  },

  methods:{
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },

    // 获取城市列表
    getCity(){
      getcityList().then(res => {
        // console.log(res);
        this.city = res.data;
      })
    },

    querySearch1(queryString, cb) {
      var restaurants = this.restaurants1;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },

    // 批量添加服务商
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {
      console.log(item);
    },

    // 单个项目的点击
    handleSelect1(item){
      console.log(item)
      this.item = item;
    },
    //单个项目的添加服务商
    add(item,index){
      if(this.item == null){
        this.$message.error('请先选择服务商')
      }else {
        console.log('可以添加了')
      }
    },
    // 删除
    del(row){
      console.log(row)
      this.data.splice(row.$index,1)
    },

    //级联选择器 选择城市
    handleChange(value) {
      const areaTextArr = [];
      const arr = this.$refs["refCascader"].getCheckedNodes()[0].pathNodes;
      arr.forEach((i) => {
        areaTextArr.push(i.label);
      });
      // console.log(areaTextArr);
      // console.log(value);
      let area = [];
      let province = value[0] + '|' + areaTextArr[0];
      let city = value[1] + '|' + areaTextArr[1];
      let district = value[2] + '|' + areaTextArr[2];
      area.push(province,city,district);
      console.log(area);
    },

    //点击返回按钮
    returnPage(){
      this.$emit('showDetails',false);
    },


  },

}
</script>

<style lang="scss" scoped>
  .divstyle1{
    margin-top: 20px;border: 1px solid #eeeeee;padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .stylekaiguan{
    display: flex;
    align-items: center;
  }
  .divstyle2{
    font-size: 18px;font-weight: 500;color: #4e4e4e;
  }
  .divstyle3{
    display: flex;
  }
  .divstyle4{
    margin-left: 20px;
    font-size: 16px;
  }

  .inline-input{
    margin-left: 20px;
  }
  .fontbianbju{
    margin-right: 15px;
  }
  .divstyle6{
    display: flex;
    align-items: center;
  }
  .divstyle7{
    margin-left: 20px;
  }
  .jiuyuanleixing{
    font-size: 18px;font-weight: 500;color: #4e4e4e;
  }
  .div-top{
    margin-top: 20px;
  }
  .meiyikuai{
    margin-top: 20px;
    .meiyikuaidiv{
      padding: 20px;
      background: #EAEAEA;
      border-radius: 4px;
      -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.16);
      display: flex;align-items: center;justify-content: space-between;
    }
    .meiyikuaidivbottom{
      border-radius: 4px;
      -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.16);
      box-shadow: 0 3px 6px rgba(0,0,0,.16);
      margin-bottom: 24px;
    }
  }

  .zuoleft{
    margin-left: 15px;
  }

  .meiyikuaidivright{
    display: flex;
    align-items: center;
  }

  .bottomstyle{
    margin-bottom: 20px;
  }
</style>
